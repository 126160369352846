<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 page-background mainArea">
      <TopBar title="Update ObjectIDs" />

      <div class="page-background d-flex flex-column align-center px-1">
        <v-card
          class="my-3"
          :width="$vuetify.breakpoint.xsOnly ? '100%' : '70%'"
        >
          <v-card-title class="text-h5 d-flex gap">
            <div class="circle d-flex justify-center align-center">1</div>
            Export Custom Template
          </v-card-title>
          <v-card-text>
            <validation-observer
              ref="exportCustomTemplateForm"
              slim
              v-slot="{ valid }"
            >
              <p class="text-caption">
                Select a feature service layer that needs its ObjectIDs updated.
              </p>

              <validation-provider
                v-slot="{ errors, valid }"
                name="Layer"
                rules="required"
              >
                <v-autocomplete
                  autocomplete="off"
                  v-model="mapServiceId"
                  label="Layer"
                  :items="mapServiceChoices"
                  item-text="label"
                  item-value="value"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  hide-details="auto"
                  @change="getGisFields"
                ></v-autocomplete>
              </validation-provider>

              <p class="text-caption">
                Optionally, you can add a date range for updating features with
                data from a specific time period.
              </p>

              <section
                class="d-flex align-center justify-space-between flex-wrap my-2 gap"
              >
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="fromDate"
                  class="flex-grow-1"
                >
                  <v-menu
                    offset-y
                    min-width="auto"
                    ref="fromDateMenu"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        label="From Date"
                        color="primary"
                        v-model="fromDate"
                        :error-messages="errors"
                        :success="valid"
                        hide-details="auto"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      @click:date="$refs.fromDateMenu.save()"
                    ></v-date-picker>
                  </v-menu>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors, valid }"
                  name="toDate"
                  class="flex-grow-1"
                  :rules="{
                    dateSameOrAfter: '@fromDate',
                    required: Boolean(fromDate),
                  }"
                >
                  <v-menu
                    offset-y
                    min-width="auto"
                    ref="toDateMenu"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        label="To Date"
                        color="primary"
                        v-model="toDate"
                        :error-messages="errors"
                        :success="valid"
                        hide-details="auto"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toDate"
                      @click:date="$refs.toDateMenu.save()"
                    ></v-date-picker>
                  </v-menu>
                </validation-provider>
              </section>

              <section class="d-flex justify-end">
                <v-btn :disabled="!valid" @click="exportObjectIdsCsv">
                  <v-icon class="mr-1">{{ mdiExport }}</v-icon>
                  Export ObjectIDs
                </v-btn>
              </section>
            </validation-observer>
          </v-card-text>
        </v-card>

        <v-card
          class="my-3 gap"
          :width="$vuetify.breakpoint.xsOnly ? '100%' : '70%'"
        >
          <v-card-title class="text-h5 d-flex gap">
            <div class="circle d-flex justify-center align-center">2</div>
            Add New ObjectID Values
          </v-card-title>
          <v-card-text>
            <p class="text-caption">
              Add a new ObjectID value for each old ObjectID. You can use the
              old ObjectID and GIS fields to identify what the new ObjectID is.
            </p>
          </v-card-text>
        </v-card>

        <v-card
          class="my-3 gap"
          :width="$vuetify.breakpoint.xsOnly ? '100%' : '70%'"
        >
          <v-card-title class="text-h5 d-flex gap">
            <div class="circle d-flex justify-center align-center">3</div>
            Import and Update ObjectIDs
          </v-card-title>
          <v-card-text>
            <p class="text-caption">
              Import ObjectIDs from step 2 to update. Be sure to include a new
              ObjectID for each old ObjectID in the file.
            </p>

            <file-pond
              name="file"
              ref="file"
              label-idle="Tap or drop files here..."
              :allow-multiple="false"
              @addfile="onAddFile"
              @removefile="onRemoveFile"
            />

            <section class="text-caption" v-if="objectIdFile">
              {{ numRowsToExport }} Rows of Data Loaded
            </section>

            <section class="text-caption" v-if="showUploadSuccessMessage">
              ObjectIDs updated successfully
            </section>

            <section class="text-caption" v-if="showUploadFailureMessage">
              There is an error updating ObjectIDs, please try again.
            </section>

            <section class="d-flex justify-end">
              <v-btn @click="updateObjectIds" :disabled="!objectIdFile">
                <v-icon class="mr-1">{{ mdiSendVariant }}</v-icon>
                Update ObjectIDs
              </v-btn>
            </section>
          </v-card-text>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import { mdiExport, mdiInformation, mdiSendVariant } from "@mdi/js";
import axios from "axios";
import vueFilePond from "vue-filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond(FilePondPluginFileEncode);
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "UpdateObjectIds",
  components: {
    TopBar,
    FilePond,
  },
  data() {
    return {
      mdiExport,
      mdiInformation,
      mdiSendVariant,
      mapServiceId: "",
      mapServices: [],
      fromDate: "",
      toDate: "",
      objectIdFile: undefined,
      showUploadSuccessMessage: false,
      showUploadFailureMessage: false,
      numRowsToExport: 0,
    };
  },
  computed: {
    mapServiceChoices() {
      return this.mapServices.map((l) => {
        const { service_name: label, map_service_id: value } = l;
        return { label, value };
      });
    },
  },
  methods: {
    async exportObjectIdsCsv() {
      const { fromDate, toDate } = this;
      const selectedMapService = this.mapServices.find((m) => {
        return m.map_service_id === this.mapServiceId;
      });
      const fileName = selectedMapService?.service_name ?? "layer";
      const {
        data: { results },
      } = await axios.get(`${APIURL}/object_id/export_features`, {
        params: {
          map_service_id: this.mapServiceId,
          created_on_start: fromDate,
          created_on_end: toDate,
        },
      });
      const { file_id: fileId } = results;
      clearInterval(this.$options.downloadTimer);
      this.$options.downloadTimer = setInterval(async () => {
        try {
          const { data } = await axios.get(
            `${APIURL}/files/big_job/${fileId}`,
            {
              responseType: "blob",
            }
          );
          const dataURL = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.download = `${fileName}.csv`;
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          link.remove();
          clearInterval(this.$options.downloadTimer);
        } catch (error) {
          console.log(error);
        }
      }, 5000);
    },
    async onAddFile() {
      const [file] = this.$refs.file.getFiles();
      this.objectIdFile = file;
      const text = await file.file.text();
      this.numRowsToExport = text.split("\n").length;
    },
    onRemoveFile() {
      this.objectIdFile = undefined;
    },
    async getLayers() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/map_services`);
      this.mapServices = results.filter((r) => r.service_type === "F");
    },
    async updateObjectIds() {
      this.showUploadSuccessMessage = false;
      this.showUploadFailureMessage = false;
      try {
        const payload = new FormData();
        payload.append("file", this.objectIdFile.file);
        await axios.put(`${APIURL}/object_id`, payload);
        this.objectIdFile = undefined;
        this.showUploadSuccessMessage = true;
      } catch (error) {
        console.warn(error?.error);
        this.showUploadFailureMessage = true;
      } finally {
        this.$refs.file.removeFiles();
      }
    },
  },
  beforeMount() {
    this.getLayers();
  },
};
</script>

<style scoped>
.circle {
  background-color: #375f54;
  color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.gap {
  gap: 15px;
}
</style>
